import React from 'react';
import { Route } from 'react-router';
import { BrowserRouter, Routes } from 'react-router-dom';


/**Importation de la vue principal*/
import Home from "../screens/home.screen";
import AppSubmissionAdd from '../screens/appSubmissionAdd.screen';
import Dashboard from '../screens/dashboard.screen';
import DashboardSubmissionList from '../screens/submissionList.screen';
import ApplicationView from '../screens/viewApplication.screen';
import AppOrder from '../screens/appOrder.screen';

class ROUTES extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}></Route>
                    <Route path='/app-submission' element={<AppSubmissionAdd/>}/>
                    <Route path='/app:' element={<ApplicationView/>}/>
                    <Route path='/classement' element={<AppOrder/>}/>
                    <Route path="/dashboard" element={<Dashboard/>}>
                        <Route path="/dashboard/UTERuiyuw1289lshwjkUIWQvYSkjbjJGKV8283972" element={<DashboardSubmissionList/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        )
    }
}

export default ROUTES;