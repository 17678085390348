import React from 'react';
import { connect } from 'react-redux';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import Progress from '../components/progress.component/progress.component';
import Card from '../components/card/card.component';

/**----------------------------------------------------------------------------------------*/
/** Importation des screens */



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/home.style.scss';
import './responsive.style/home.responsive.style.scss';




/**----------------------------------------------------------------------------------------*/
/**Importation des images*/
import imgApp from '../assets/gif/mobile-app-development.gif';
import voteIcon from '../assets/icons/vote.png';
// import imgApp2 from '../assets/images/ICONE.png';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class HomeView extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            favoris: [],
            applicationList: [],
            session: {
                email: "",
                vote: [],
            },
        }

        this.grid = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    _getSession(key) {
        return localStorage.getItem(key);
    }

    init(key) {
        let session = this._getSession(key);
        if (session !== null && session !== undefined) {
            this.setState({
                session: JSON.parse(session)
            });
            // console.log(JSON.parse(session).vote.indexOf(""+14));
        }
    }

    componentDidMount() {
        this.init("applicationUIT");
        this.setState({
            isLoading: true,
        });
        // localStorage.removeItem("applicationUIT");
        this._getAllSubmission((response) => {
            this.setState({
                applicationList: response.data,
            });

            this._getFavoris((response) => {
                // console.log(response);
                this.setState({
                    isLoading: false,
                    favoris: response.data,
                });
            }, (e) => {
                this.setState({
                    isLoading: false,
                });
            })
        }, (e) => {
            this.setState({
                isLoading: false,
            });
        });
    }

    _voteAction(id) {
        this.setState({
            isLoading: true,
        });
        if(this.state.session.email === "") {
            let email = prompt("Entrez votre adresse email svp. Veuillez vous assurer qu'elle soit valide");
            if(email) {
                const confirmation = window.confirm("Êtes vous sûr de la validité de votre adresse email ?\n" + email);
                if(confirmation) {
                    let data = new FormData();
                    data.append("application_id", id);
                    data.append("email", email);

                    this._addVote(data, (res) => {
                        // console.log(res.data);
                        if(res.data.code === 201) {
                            let session = this.state.session;
                            session.email = email;
                            session.vote.push(id);

                            this.setState({
                                session: session,
                                isLoading: false,
                            });
                            localStorage.setItem("applicationUIT", JSON.stringify(session));
                            this.componentDidMount();
                        } else if(res.data.code === 400) {
                            alert("Un vote a déjà été éffectué avec cet email");
                            this.setState({
                                isLoading: false,
                            });
                        }
                    }, (e) => {
                        this.setState({
                            isLoading: false,
                        });
                    })
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        } else {
            if(this.state.session.vote.indexOf(""+id) !== -1) {
                let data = new FormData();
                data.append("application_id", id);
                data.append("email", this.state.session.email);
                this._deleteVote(data, (res) => {
                    // console.log(res.data);
                    if(res.data.code===200) {
                        let session = this.state.session;
                        session.vote.splice(this.state.session.vote.indexOf(""+id), 1);
                        this.setState({
                            session: session,
                        });
                        localStorage.setItem("applicationUIT", JSON.stringify(session));
                        this.componentDidMount();
                    }
                    this.setState({
                        isLoading: false,
                    });
                }, (e) => {
                    this.setState({
                        isLoading: false,
                    });
                })
            } else {
                const confirmation = window.confirm("Êtes vous sûr de vouloir voter pour cette application ?");
                if(confirmation) {
                    let data = new FormData();
                    data.append("application_id", id);
                    data.append("email", this.state.session.email);

                    this._addVote(data, (res) => {
                        // console.log(res.data);
                        if(res.data.code === 201) {
                            let session = this.state.session;
                            session.vote.push(id);

                            this.setState({
                                session: session,
                                isLoading: false,
                            });
                            localStorage.setItem("applicationUIT", JSON.stringify(session));
                            this.componentDidMount();
                        } else if(res.data.code === 400) {
                            alert("Un vote a déjà été éffectué avec cet email");
                            this.setState({
                                isLoading: false,
                            });
                        }
                    }, (e) => {
                        this.setState({
                            isLoading: false,
                        });
                    })
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }
            }
        }
    }

    render() {
        return (
            <>
                {
                    super.render()
                }
                <div className='header'>
                    <div className='header_title'>
                        <h1>
                            Application UIT
                        </h1>
                        <h1 className='year'>
                            2023
                        </h1>
                    </div>
                    <div className='header_img'>
                        <img src={imgApp} alt='application-uit-2023'/>
                    </div>
                </div>

                <section className='section_'>
                    <section className='section__all'>
                        <div className='section__all_content'>
                            {
                                this.state.applicationList.map((app, index) => (
                                    <div className='section__all_content_card'>
                                        <Card
                                            id={app.id}
                                            name={app.name}
                                            head={
                                                <Progress 
                                                    img={this.PATH_.backend + app.image[0].name}
                                                    progression={this.state.favoris.length>0 && ((app.vote_count*100)/Number(this.state.favoris[0].vote_count))}
                                                />}
                                            short_description={app.short_description}
                                            author={app.author}
                                            vote={app.vote_count}
                                            voteIcon={voteIcon}
                                            internship_supervisor={app.internship_supervisor}
                                            memory_director={app.memory_director}
                                            isVoted={this.state.session.vote.indexOf(""+app.id) !== -1 ? true : false}
                                            voteAction={(id) => {
                                                this._voteAction(id);
                                            }}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </section>
                    <section className='section__favorite'>
                        {
                            this.state.favoris.map((fav, index) => (
                                <div className='section__favorite_content'>
                                    <div className='section__favorite_content_title'>
                                        <span>
                                            {fav.name}
                                        </span>
                                    </div>
                                    <div className='section__favorite_content_progress'>
                                        <Progress img={this.PATH_.backend + fav.image[0].name} progression={100} />
                                    </div>
                                    <div className='section__favorite_content_shortDescription'>
                                        {fav.short_description}
                                    </div>
                                    <div className='section__favorite_content_button'>
                                        <button
                                            className={this.state.session.vote.indexOf(""+fav.id) !== -1 ? "isVoted" : "notVoted"}
                                        >
                                            <img src={voteIcon} alt=''/>
                                            <span>{fav.vote_count}</span>
                                        </button>
                                        <a href={"/app:?application=" + encodeURIComponent(fav.id)}>
                                            Voir plus
                                        </a>
                                    </div>
                                </div>
                            ))
                        }
                        <div className='section__favorite_vote'>
                            <a href='/classement'>
                                <span>Voir le classement</span>
                            </a>
                        </div>
                    </section>
                </section>
            </>
        )
    }
}


HomeView = connect(mapStateToProps, null)(HomeView);
export default HomeView;