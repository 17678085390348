import React from 'react';
import { connect } from 'react-redux';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */

/**----------------------------------------------------------------------------------------*/
/** Importation des screens */



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/appSubmissionAdd.style.scss';
import './responsive.style/home.responsive.style.scss';




/**----------------------------------------------------------------------------------------*/
/**Importation des images*/
import imgApp from '../assets/gif/mobile-app-development.gif';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class AppSubmissionAdd extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            appActorsList: [],
            currentActor: "",
            app_images: [],
            app_images_PreviewLink: [],
            app_video: [],
            app_video_PreviewLink: [],
        }

        this.grid = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        
    }

    _addAppActor(actor) {
        let appActorsList = this.state.appActorsList;
        if (appActorsList.indexOf(actor) !== -1) {
            alert("Auteur déjà ajouté");
        } else {
            appActorsList.push(actor);
            this.setState({
                appActorsList: appActorsList,
                currentActor: "",
            });
        }
    }

    _deleteAppActor(index) {
        let appActorsList = this.state.appActorsList;
        appActorsList.splice(index, 1);
        this.setState({
            appActorsList: appActorsList,
        });
    }

    _deleteImage(imageIndex) {
        let app_images = this.state.app_images;
        let app_images_PreviewLink = this.state.app_images_PreviewLink;

        let filtered_app_images = app_images.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        let filtered_app_images_PreviewLink = app_images_PreviewLink.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        this.setState({
            app_images: filtered_app_images,
            app_images_PreviewLink: filtered_app_images_PreviewLink,
        });
    }

    _addApplication() {
        this.setState({
            isLoading: true,
        });
        let data = new FormData();

        data.append("name", document.forms["submissionForm"]["app_name"].value);
        data.append("author", JSON.stringify(this.state.appActorsList));

        for (let i = 0; i < this.state.app_images.length; i++) {
            data.append("files[]", this.state.app_images[i]);
        }

        for (let i = 0; i < this.state.app_video.length; i++) {
            data.append("files[]", this.state.app_video[i]);
        }
        
        data.append("folder", document.forms["submissionForm"]["app_name"].value);
        data.append("short_description", document.forms["submissionForm"]["short_description"].value);
        data.append("long_description", document.forms["submissionForm"]["long_description"].value);
        data.append("internship_supervisor", document.forms["submissionForm"]["internship_supervisor"].value);
        data.append("memory_director", document.forms["submissionForm"]["memory_director"].value);

        this._addSubmission(data, (res) => {
            this.setState({
                isLoading: false,
            });
            console.log(res.data);
        }, (e) => {
            this.setState({
                isLoading: false,
            });
            console.log(e.response);
        })
    }

    render() {
        return (
            <>
                {
                    super.render()
                }
                <section className='section'>
                    <form className='section_form' action='#' method='POST' id='submissionForm' name='submissionForm'
                        onSubmit={(e) => {
                            e.preventDefault();
                            this._addApplication();
                        }}
                    >
                        <div className='section_form_img'>
                            <img src={imgApp} alt=''/>
                        </div>
                        <div className='section_form_content'>
                            <div className='section_form_content_triangle1'></div>
                            <div className='section_form_content_triangle2'></div>
                            <div className='section_form_content_title'>
                                <h2>
                                    Soumettre une application
                                </h2>
                            </div>

                            <div className='section_form_content_input'>
                                <div>
                                    <label>
                                        Nom de l'application
                                    </label>
                                </div>
                                <div className='section_form_content_input_balise'>
                                    <div className='section_form_content_input_balise_icon'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                        </svg>
                                    </div>
                                    <input required type='text' name='app_name' />
                                </div>
                            </div>

                            <div className='section_form_content_input'>
                                <div>
                                    <label>
                                        Noms et Prénoms des auteurs
                                    </label>
                                </div>
                                <div className='section_form_content_input_balise'>
                                    <div className='section_form_content_input_balise_icon'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                        </svg>
                                    </div>
                                    <input type='text' value={this.state.currentActor} name
                                        onChange={(e) => {
                                            this.setState({
                                                currentActor: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className='section_form_content_input_button'>
                                    <span className='button_style_1'
                                        onClick={(e)=>{
                                            if(this.state.currentActor !== "") {
                                                this._addAppActor(this.state.currentActor);
                                            }else {
                                                alert("Le nom et prénom(s) de l'auteur ne sont pas renseignés");
                                            }
                                        }}
                                    >
                                        Ajouter un auteur
                                    </span>
                                </div>

                                <div className='section_form_content_input_list'>
                                    {
                                        this.state.appActorsList.map((actor, index) => (
                                            <div className='section_form_content_input_list_elt'>
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                </svg>
                                                <span>
                                                    {actor}
                                                </span>
                                                <div className='section_form_content_input_list_elt_trash'>
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                                        onClick={(e) => {
                                                            this._deleteAppActor(index);
                                                        }}
                                                    >
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className='section_form_content_input'>
                                <div>
                                    <label>
                                        Ajouter des images de l'application
                                    </label>
                                </div>
                                <div className='section_form_content_input_balise'>
                                    <div className='section_form_content_input_balise_icon'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                        </svg>
                                    </div>
                                    <input type='file' multiple accept="image/*,.gif,.GIF,.Gif" name='image' 
                                        onChange={async (e) => {
                                            const files = Array.from(e.target.files);
                                            let app_images = this.state.app_images;
                                            let tab = this.state.app_images_PreviewLink;

                                            app_images = app_images.concat(files);
        
                                            Promise.all(files.map(file => {
                                                return (
                                                    new Promise((resolve, reject) => {
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', (ev) => {
                                                            resolve(ev.target.result);
                                                        });
                                                        reader.addEventListener('error', reject);
                                                        reader.readAsDataURL(file);
                                                    })
                                                )
                                            })).then(images => {
                                                tab = tab.concat(images);
                                                this.setState({
                                                    app_images: app_images,
                                                    app_images_PreviewLink: tab,
                                                });
                                            });
                                        }}
                                    />
                                </div>

                                <div className='section_form_content_input_balise_imagesList'>
                                    {
                                        this.state.app_images_PreviewLink.map((link, index) => (
                                            <div className='section_form_content_input_balise_imagesList_elt'>
                                                <svg onClick={() => {
                                                    this._deleteImage(index);
                                                }} className='section_form_content_input_balise_imagesList_elt_cross' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529" />
                                                    <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529" />
                                                </svg>
                                                <img src={link} alt=""/>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className='section_form_content_input'>
                                <div>
                                    <label>
                                        Ajouter une vidéo de l'application
                                    </label>
                                </div>
                                <div className='section_form_content_input_balise'>
                                    <div className='section_form_content_input_balise_icon'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                        </svg>
                                    </div>
                                    <input type='file' name='video'  accept="video/*"
                                        onChange={async (e) => {
                                            const files = Array.from(e.target.files);
                                            let app_video = this.state.app_video;
                                            let tab = this.state.app_video_PreviewLink;

                                            app_video = files;
        
                                            Promise.all(files.map(file => {
                                                return (
                                                    new Promise((resolve, reject) => {
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', (ev) => {
                                                            resolve(ev.target.result);
                                                        });
                                                        reader.addEventListener('error', reject);
                                                        reader.readAsDataURL(file);
                                                    })
                                                )
                                            })).then(video => {
                                                tab = video;
                                                this.setState({
                                                    app_video: app_video,
                                                    app_video_PreviewLink: tab,
                                                });
                                            });
                                        }}
                                    />
                                </div>
                                <div className='section_form_content_input_balise_video'>
                                    <div className='section_form_content_input_balise_video_elt'>
                                        <video controls src={this.state.app_video_PreviewLink[0]} alt=""></video>
                                    </div>
                                </div>
                            </div>

                            <div className='section_form_content_input'>
                                <div>
                                    <label>
                                        Ajouter un court descriptif de l'application
                                    </label>
                                </div>
                                <div className='section_form_content_input_balise'>
                                    <div className='section_form_content_input_balise_icon'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                        </svg>
                                    </div>
                                    <textarea required className='short' name='short_description'></textarea>
                                </div>
                            </div>

                            <div className='section_form_content_input'>
                                <div>
                                    <label>
                                        Ajouter une longue description de l'application
                                    </label>
                                </div>
                                <div className='section_form_content_input_balise'>
                                    <div className='section_form_content_input_balise_icon'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                        </svg>
                                    </div>
                                    <textarea required className='long' name='long_description'></textarea>
                                </div>
                            </div>

                            <div className='section_form_content_input'>
                                <div>
                                    <label>
                                        Encadreur de stage
                                    </label>
                                </div>
                                <div className='section_form_content_input_balise'>
                                    <div className='section_form_content_input_balise_icon'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                        </svg>
                                    </div>
                                    <input required type='text' name='internship_supervisor' />
                                </div>
                            </div>

                            <div className='section_form_content_input'>
                                <div>
                                    <label>
                                        Directeur de mémoire
                                    </label>
                                </div>
                                <div className='section_form_content_input_balise'>
                                    <div className='section_form_content_input_balise_icon'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                        </svg>
                                    </div>
                                    <input required type='text' name='memory_director' />
                                </div>
                            </div>

                            <div className='section_form_content_footer'>
                                <button className='button_style_2'>
                                    Soumettre
                                </button>
                            </div>
                        </div>
                    </form>
                </section>
            </>
        )
    }
}


AppSubmissionAdd = connect(mapStateToProps, null)(AppSubmissionAdd);
export default AppSubmissionAdd;