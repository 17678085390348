import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */

/**----------------------------------------------------------------------------------------*/
/** Importation des screens */
import HomeView from './home_view.screen';
import AppSubmissionAdd from './appSubmissionAdd.screen';
import ApplicationView from './viewApplication.screen';
import AppOrder from './appOrder.screen';

/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/home.style.scss';
import './responsive.style/home.responsive.style.scss';




/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class Home extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
        }

        this.grid = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        
    }
   
    render() {
        return (
            <>
                {
                    super.render()
                }
                <div className='container'>
                    <div>
                        <Routes>
                            <Route path='*' element={<HomeView/>}/>
                            <Route path='/app-submission' element={<AppSubmissionAdd/>}/>
                            <Route path='/app:' element={<ApplicationView/>}/>
                            <Route path='/classement' element={<AppOrder/>}/>
                        </Routes>
                    </div>
                </div>
            </>
        )
    }
}


Home = connect(mapStateToProps, null)(Home);
export default Home;