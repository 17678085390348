import './App.css';
import ROUTES from './routes/routes';
import './global-style/animation.css';
import './plugin/font-awesome-4.7.0/css/font-awesome.min.css';
import './global-style/default.scss';
import './font_style/font.css'

function App() {
    return (
        <div className="App">
            <ROUTES/>
        </div>
    );
}

export default App;
