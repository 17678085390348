import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class VoteRequest {
    getFavoris() {
        return (
            axios({
                method: 'get',
                url: PATH__.api + "vote/index.php?favoris",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        )
    }

    add(data) {
        return (
            axios({
                method: 'post',
                url: PATH__.api + "vote/index.php",
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        )
    }

    delete(data) {
        return (
            axios({
                method: 'post',
                url: PATH__.api + "vote/index.php?deleteVote",
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        )
    }
}

export default VoteRequest;