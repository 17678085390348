import React from 'react';
import { connect } from 'react-redux';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
// import Progress from '../components/progress.component/progress.component';
// import Card from '../components/card/card.component';

/**----------------------------------------------------------------------------------------*/
/** Importation des screens */



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/home.style.scss';
import './responsive.style/home.responsive.style.scss';




/**----------------------------------------------------------------------------------------*/
/**Importation des images*/
import imgApp from '../assets/gif/mobile-app-development.gif';
import voteIcon from '../assets/icons/vote.png';
import loaderB from '../assets/gif/loader.gif';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class ApplicationView extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            currentApp: {},
            session: {
                email: "",
                vote: [],
            },
        }

        this.grid = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    _getSession(key) {
        return localStorage.getItem(key);
    }

    init(key) {
        let session = this._getSession(key);
        if (session !== null && session !== undefined) {
            this.setState({
                session: JSON.parse(session)
            });
            // console.log(JSON.parse(session).vote.indexOf(""+14));
        }
    }

    componentDidMount() {
        this.init("applicationUIT");
        let query = new URLSearchParams(window.location.search);
        let applicationId =  decodeURIComponent(query.get("application"));

        this.setState({
            isLoading: true,
        });

        this._getOneSubmission(applicationId, (res)=>{
            this.setState({
                currentApp: res.data[0],
                isLoading: false,
            });

            // console.log(res.data);
        }, (e)=>{
            this.setState({
                isLoading: false,
            });
        })
    }

    _voteAction(id) {
        this.setState({
            isLoading: true,
        });
        if(this.state.session.email === "") {
            let email = prompt("Entrez votre adresse email svp. Veuillez vous assurer qu'elle soit valide");
            if(email) {
                const confirmation = window.confirm("Êtes vous sûr de la validité de votre adresse email ?\n" + email);
                if(confirmation) {
                    let data = new FormData();
                    data.append("application_id", id);
                    data.append("email", email);

                    this._addVote(data, (res) => {
                        // console.log(res.data);
                        if(res.data.code === 201) {
                            let session = this.state.session;
                            session.email = email;
                            session.vote.push(id);

                            this.setState({
                                session: session,
                                isLoading: false,
                            });
                            localStorage.setItem("applicationUIT", JSON.stringify(session));
                            this.componentDidMount();
                        } else if(res.data.code === 400) {
                            alert("Un vote a déjà été éffectué avec cet email");
                            this.setState({
                                isLoading: false,
                            });
                        }
                    }, (e) => {
                        this.setState({
                            isLoading: false,
                        });
                    })
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        } else {
            if(this.state.session.vote.indexOf(""+id) !== -1) {
                let data = new FormData();
                data.append("application_id", id);
                data.append("email", this.state.session.email);
                this._deleteVote(data, (res) => {
                    // console.log(res.data);
                    if(res.data.code===200) {
                        let session = this.state.session;
                        session.vote.splice(this.state.session.vote.indexOf(""+id), 1);
                        this.setState({
                            session: session,
                        });
                        localStorage.setItem("applicationUIT", JSON.stringify(session));
                        this.componentDidMount();
                    }
                    this.setState({
                        isLoading: false,
                    });
                }, (e) => {
                    this.setState({
                        isLoading: false,
                    });
                })
            } else {
                const confirmation = window.confirm("Êtes vous sûr de vouloir voter pour cette application ?");
                if(confirmation) {
                    let data = new FormData();
                    data.append("application_id", id);
                    data.append("email", this.state.session.email);

                    this._addVote(data, (res) => {
                        // console.log(res.data);
                        if(res.data.code === 201) {
                            let session = this.state.session;
                            session.vote.push(id);

                            this.setState({
                                session: session,
                                isLoading: false,
                            });
                            localStorage.setItem("applicationUIT", JSON.stringify(session));
                            this.componentDidMount();
                        } else if(res.data.code === 400) {
                            alert("Un vote a déjà été éffectué avec cet email");
                            this.setState({
                                isLoading: false,
                            });
                        }
                    }, (e) => {
                        this.setState({
                            isLoading: false,
                        });
                    })
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }
            }
        }
    }

    render() {
        return (
            <>
                {
                    super.render()
                }
                <div className='backLoader'>
                    <img src={loaderB} alt='' />
                    <div className='backLoader_cov'></div>
                </div>
                <div className='header'>
                    <div className='header_title'>
                        <h1>
                            Application UIT
                        </h1>
                        <h1 className='year'>
                            2023
                        </h1>
                    </div>
                    <div className='header_img'>
                        <img src={imgApp} alt='application-uit-2023'/>
                    </div>
                </div>

                <section className='section_'>
                    <section className='section__all'>
                        {/* <div className='section__all_content'> */}
                            {/* description, images, videos */}

                            
                        {/* </div> */}

                        <div className='section__all_text short'>
                            {this.state.currentApp.short_description}        
                        </div>

                        <div className='section__all_text'>
                            {this.state.currentApp.long_description}        
                        </div>

                        <div className='section__all_images'>
                            {
                                this.state.currentApp.image &&
                                this.state.currentApp.image.map((img)=>(
                                    <div className='section__all_images_elt'>
                                        <img src={this.PATH_.backend + img.name} alt=""/>
                                    </div>
                                ))
                            }
                        </div>

                        <div className='section__all_video'>
                            {
                                this.state.currentApp.video &&
                                <video controls src={this.PATH_.backend + this.state.currentApp.video[0].name}></video>
                            }
                        </div>

                        <div className='section__all_footer'>
                            Encadreur de stage: <span>{this.state.currentApp.internship_supervisor}</span>
                            <br/>
                            Directeur de mémoire: <span>{this.state.currentApp.memory_director}</span>
                        </div>
                    </section>
                    <section className='section__favorite'>
                        {/* Image principal de l'application */}
                        <div className='section__favorite_gard'>
                            <img alt='' src={this.state.currentApp.image ? this.PATH_.backend + this.state.currentApp.image[0].name : ""} />
                        </div>

                        <div className='section__favorite_vote'>
                            <button className={this.state.session.vote.indexOf(""+this.state.currentApp.id) !== -1 ? "isVoted" : "notVoted"}
                                onClick={(e) => {
                                    this._voteAction(this.state.currentApp.id);
                                }}
                            >
                                <span>{this.state.currentApp.vote_count && this.state.currentApp.vote_count}</span>
                                <img src={voteIcon} alt=''/>
                            </button>
                        </div>
                    </section>
                </section>
            </>
        )
    }
}


ApplicationView = connect(mapStateToProps, null)(ApplicationView);
export default ApplicationView;