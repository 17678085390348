import React from 'react';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */




/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */



/**----------------------------------------------------------------------------------------*/
/**Importation des images*/
import loader from '../assets/gif/loader.gif';
import loader2 from '../assets/gif/loader-2.gif';

/**
 * Importation des variables globals
 */
import VARIABLES from '../model-7-1/utils/_variables.scss';
import GLOBAL from '../config';
import PATH_ from '../GLOBAL-VAR/globals-urls';
/**
 * Importation des helpers
 */
import AccessStore from '../store/accessStore';

import tools from '../helpers/tools.helper';

import SubmissionRequest from '../helpers/submission.request.helper';
import VoteRequest from '../helpers/vote.request.helper';


class SuperScreen extends React.Component {
    constructor(props) {
        super(props);
        this.VAR = VARIABLES;
        this.GLOBAL = new GLOBAL();

        this.state = {
            isLoading: false,
            pageIsReady: false,
        }
        
        this.logo = this.GLOBAL.images.logo_;
        
        this.tools = new tools();
        this.accessStore = new AccessStore( this.props.dispatch);
        this.SubmissionRequest = new SubmissionRequest();
        this.VoteRequest = new VoteRequest();
        this.PATH_ = new PATH_();
    }

    componentDidMount() {
        
    }

    _generateRandomColor(_number_){
        let maxVal = 0xFFFFFF; // 16777215
        let randomNumber = _number_ * maxVal; 
        randomNumber = Math.floor(randomNumber);
        randomNumber = randomNumber.toString(16);
        let randColor = randomNumber.padStart(6, 0);   
        return `#${randColor.toUpperCase()}`
    }

    _generateID(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++)
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    _getFavoris(success_callback=()=>{},  failed_callback=()=>{}) {
        this.VoteRequest.getFavoris().then((response) => {
            success_callback( response );
        }).catch((e) => { 
            this._manageError(e, () => {
                failed_callback( e );
            });
        });
    }

    _addVote(data, success_callback=()=>{},  failed_callback=()=>{}) {
        this.VoteRequest.add(data).then((response) => {
            success_callback( response );
        }).catch((e) => { 
            this._manageError(e, () => {
                failed_callback( e );
            });
        });
    }

    _deleteVote(data, success_callback=()=>{},  failed_callback=()=>{}) {
        this.VoteRequest.delete(data).then((response) => {
            success_callback( response );
        }).catch((e) => { 
            this._manageError(e, () => {
                failed_callback( e );
            });
        });
    }

    /** 
     * 
     * L'ensemble des requests pour les soumissions
    */
    _getAllSubmission(success_callback=()=>{},  failed_callback=()=>{}) {
        this.SubmissionRequest.getAll().then((response) => {
            success_callback( response );
        }).catch((e) => { 
            this._manageError(e, () => {
                failed_callback( e );
            });
        });
    }

    _getAllSubmissionOrder(success_callback=()=>{},  failed_callback=()=>{}) {
        this.SubmissionRequest.getOrder().then((response) => {
            success_callback( response );
        }).catch((e) => { 
            this._manageError(e, () => {
                failed_callback( e );
            });
        });
    }

    _getOneSubmission(id, success_callback=()=>{},  failed_callback=()=>{}) {
        this.SubmissionRequest.getOne(id).then((response) => {
            success_callback( response );
        }).catch((e) => { 
            this._manageError(e, () => {
                failed_callback( e );
            });
        });
    }

    _addSubmission(data, success_callback=()=>{},  failed_callback=()=>{}) {
        this.SubmissionRequest.add(data).then((response) => {
            success_callback( response );
        }).catch((e) => { 
            this._manageError(e, () => {
                failed_callback( e );
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if (error.toString() === "Error: Network Error") {
            alert("Aucun accès à internet :(");
            callback();
        }
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading ?
                        <div className='loading'>
                            <img src={loader} alt='application-uit-2023'/>
                            <img className='loader2' src={loader2} alt='application-uit-2023'/>
                        </div>
                    :
                        null
                }
            </>
        )
    }
}

export default SuperScreen;