import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';



//**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**Importation des styles de la page */
import './styles/dashboard.style.scss';



/**Importation des images*/



/**Importation des helpers */
// import text from '../GLOBAL-VAR/global-text';

/**
 * 
 * Importation des screens
 */
import DashboardSubmissionList from './submissionList.screen';



function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}


class Dashboard extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            printDashboard: true,
        }
    }

    componentDidMount() {
        
    }
    render() {
        return (
            <>
                {
                    // !this._isObjEmpty(this.props.user) ?
                    // <div>
                    //     <div style={{position: "absolute", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    //         <a href='/login' className='button-style-5'>
                    //             Se connecter
                    //         </a>
                    //     </div>
                    // </div>
                    // : this.state.printDashboard &&
                    <main className='dashboard'>
                        <div className="dashboard-contain">
                            <Routes>
                                <Route path='/Qty632yuiytwqgdw73728gd268dyd28ys3287y' element={<DashboardSubmissionList/>}/>
                            </Routes>
                        </div>
                    </main>
                }
            </>
        )
    }
}

Dashboard = connect(mapStateToProps, null)(Dashboard);

export default Dashboard;
