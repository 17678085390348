// import { ReactDOM } from 'react';
import React from 'react';

/**Importation des styles de la page */
import './style/style.scss';
import './style/responsive.style.css';


/**Importation des images*/


class Progress extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
           
        }
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <div className='Progress_'>
                <div className="progress_">
                    
                    <svg className="progress_-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
                        
                        <circle className="progress_-circle-back" cx="75" cy="75" r="70"></circle>
                        <circle className="progress_-circle-prog" cx="75" cy="75" r="70"
                            style={{
                                strokeDasharray: this.props.progression * ((4.64 * 100) /  100) + ' 999',
                                stroke: (this.props.progression < 50) ? "#0E7699" : (
                                    (this.props.progression >= 50 && this.props.progression < 90) ? "#f17e12" :"#5dc269"
                                )
                            }}
                        ></circle>
                        <image href={this.props.img} alt='' x="20%" y="20%" width="60%" height="60%"/>
                    </svg>
                </div>
            </div>
        )
    }
}

export default Progress;