import logo from './assets/logos/logo.png';

class GLOBAL {
    constructor() {
        this.images = {
            logo_: logo,
        }
    }
}

export default GLOBAL;