import React from 'react';
import { connect } from 'react-redux';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */


/**----------------------------------------------------------------------------------------*/
/** Importation des screens */



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */



/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class DashboardSubmissionList extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
        }
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        
    }
    render() {
        return (
            <>
                {
                    super.render()
                }
            </>
        )
    }
}


DashboardSubmissionList = connect(mapStateToProps, null)(DashboardSubmissionList);
export default DashboardSubmissionList;