import React from 'react';

import './style/style.scss';
import './style/responsive.style.css';

class Card extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectLink: '',
        }
    }

    componentDidMount() {
        
    }


    render() {
        return (
            <div className="Card">
                <div className="Card_imageContainer">
                    <a href={"/app:?application=" + encodeURIComponent(this.props.id)} id={this.props.name}>
                        {this.props.head}
                    </a>
                    <div className="Card_odd">
                        {/* {
                            this.props.oddList.slice(0).reverse().map((odd, index) => (
                                <div key={"ODD" + index} className="odd">
                                    <Link to={"/ODD:?odd=" + encodeURIComponent(odd)}
                                        onClick={()=>{
                                            if( this.props.reload ) {
                                                this.props.actionOdd(odd);
                                            }
                                        }}
                                    >
                                        <img className="odd_" alt="odd" src={"./ODD/odd" +odd+ ".png"}/>
                                    </Link>
                                </div>
                            ))
                        } */}
                    </div>
                </div>
                <div className="Card_body">
                    <a href={"/app:?application=" + encodeURIComponent(this.props.id)}>
                        <div className="Card_body_title">
                            <span>{ this.props.name }</span>
                        </div>
                    </a>
                    <div className="card_separator_line"></div>
                    <div className="Card_body_text textForm lineHeight1">
                        <span>{ this.props.short_description }</span>
                    </div>
                    <div className="Card_competence">
                        {
                            this.props.author.map((competence, index) => (
                                <div key={"competence" + index} className="competence">
                                    <a className="link" href={"/app:?application=" + encodeURIComponent(this.props.id)}>
                                        <span>
                                            { competence }
                                        </span>
                                    </a>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='card_info'>
                    <div>
                        Encadreur de stage: <span>{this.props.internship_supervisor}</span>
                    </div>
                    <div>
                        Directeur de mémoire: <span>{this.props.memory_director}</span>
                    </div>
                </div>
                <div className='Card_footer'>
                    <button className={this.props.isVoted ? "isVoted" : "notVoted"}
                        onClick={(e) => {
                            this.props.voteAction(this.props.id);
                        }}
                    >
                        <img src={this.props.voteIcon} alt=''/>
                        <span>{this.props.vote}</span>
                    </button>
                    <a href={"/app:?application=" + encodeURIComponent(this.props.id)}>
                        Voir plus
                    </a>
                </div>
            </div>
        )
    }
}

export default Card;