class tools {
    _sortByDate(tab, key) {
        return (
            tab.sort(function(a, b) {
                return new Date(a[key]) - new Date(b[key]);
            })
        )
    }

    _sortByKey(tab, key) {
        return (
            tab.sort(function(a, b) {
                return a[key] - b[key];
            })
        )
    }

    _basicSort(tab) {
        return (
            tab.sort(function(a, b) {
                return a - b;
            })
        )
    }

    _containObject(object, list, key) {
        var i = 0;
        for (i in list) {
            if (list[i][key] === object[key]) {
                return i;
            }
        }
        return false;
    }

    _byteToHexString(uint8arr) {
        if (!uint8arr) {
            return '';
        }

        var hexStr = '';
        for (var i = 0; i < uint8arr.length; i++) {
            var hex = (uint8arr[i] & 0xff).toString(16);
            hex = (hex.length === 1) ? '0' + hex : hex;
            hexStr += hex;
        }

        return hexStr.toUpperCase();
    }

    _hexStringToByte(str) {
        console.log(str);
        if (!str) {
            return new Uint8Array();
        }

        var a = [];
        for (var i = 0, len = str.length; i < len; i += 2) {
            a.push(parseInt(str.substr(i, 2), 16));
        }

        return new Uint8Array(a);
    }

    _delectDouble(tab, key) {
        const tabSorted = this._sortByKey(tab, key);
        let newTab = [];
        for( let i=0; i<tabSorted.length; i++ ) {
            if( i > 0 ) {
                if( tabSorted[i-1][key] !== tabSorted[i][key] ) {
                    newTab.push( tabSorted[i] );
                }
            }

            else {
                newTab.push( tabSorted[i] );
            }
        }
        return newTab;
    }
}

export default tools;