class PATH_ {
    constructor() {
        // this.api = "http://localhost:8080/application-uit-2023-backend/model/";
        // this.api_ = "http://localhost:8080/application-uit-2023-backend/model";
        // this.backend = "http://localhost:8080/application-uit-2023-backend/";
        this.api = "https://application-uit.000webhostapp.com/model/";
        this.api_ = "https://application-uit.000webhostapp.com/model";
        this.backend = "https://application-uit.000webhostapp.com/";
    }
}

export default PATH_;